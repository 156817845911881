import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState, useContext } from 'react';
import { Switch } from '@headlessui/react';
import { CiCircleQuestion } from 'react-icons/ci';
import { Tooltip } from '@material-ui/core';
import firebase from 'gatsby-plugin-firebase';
import { navigate } from 'gatsby';
import Footer from '@/components/Footer';
import Fuse from 'fuse.js';
import CreateResume from '../../components/dashboard/CreateResume';
import LoadingScreen from '../../components/router/LoadingScreen';
import ResumePreview from '../../components/dashboard/ResumePreview';
import TopNavbar from '../../components/dashboard/TopNavbar';
import Button from '../../components/shared/Button';
import Input from '../../components/shared/Input';
import { fuseOptions } from '../../utils';
import DatabaseContext from '../../contexts/DatabaseContext';

const Dashboard = ({ user }) => {
  const { t } = useTranslation();
  const [resumes, setResumes] = useState([]);
  const [rawResumes, setRawResumes] = useState([]);
  const [maxCount, setMaxCount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [comId, setComId] = useState('');
  const [company, setCompany] = useState({});
  const handleGoToReadOnly = () => navigate(`/app/dashboard/readonly/${comId}`);
  const handleGoToArchive = () => navigate(`/app/dashboardArchive`);
  const [enabled, setEnabled] = useState(false);
  const { updateCompanyInfo } = useContext(DatabaseContext);

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    const resumesRef = 'resumes';
    const companiesRef = 'companies';
    const socketRef = '/.info/connected';

    firebase
      .database()
      .ref(socketRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          setLoading(false);
          firebase.database().ref(socketRef).off();
        }
      });

    const companyId = user.managerCompanyId || user.adminCompanyId || '';
    setComId(companyId);

    if (companyId !== '') {
      firebase
        .database()
        .ref(resumesRef)
        .orderByChild('companyId')
        .equalTo(companyId)
        .on('value', (snapshot) => {
          if (snapshot.val()) {
            const data = snapshot.val();
            const resumesArr = Object.entries(data)
              .map(([, resume]) => resume)
              .filter((resume) => resume.public)
              .sort((a, b) => b.updatedAt - a.updatedAt);
            setRawResumes(resumesArr);
            setResumes(resumesArr);
          }
        });
    }

    firebase
      .database()
      .ref(companiesRef)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const arr = [];
          const data = snapshot.val();

          Object.keys(data).forEach((key) => arr.push(data[key]));
          localStorage.setItem('companies', JSON.stringify(arr));

          for (const elem of arr) {
            if (elem.id === companyId) {
              setMaxCount(elem.maxResumeCount);
              setCompany(elem);
              setEnabled(elem.replaceContact);
            }
          }
        }
      });

    firebase
      .database()
      .ref(resumesRef)
      .orderByChild('companyId')
      .equalTo(companyId)
      .on('child_removed', (snapshot) => {
        if (snapshot.val()) {
          setRawResumes(rawResumes.filter((x) => x.id === snapshot.val().id));
          setResumes(rawResumes);
        }
      });

    return () => {
      firebase.database().ref(resumesRef).off();
      firebase.database().ref(companiesRef).off();
    };
  }, [user]);

  if (loading) {
    return <LoadingScreen />;
  }

  const mappedResumes = rawResumes.map((resume) => ({
    ...resume,
    skills: resume.skills.items?.map((item) => item.name).join(','),
  }));

  const fuse = new Fuse(mappedResumes, fuseOptions);

  const handleSearch = (event) => {
    const { value } = event.target;

    if (value.length === 0) {
      setResumes(rawResumes);
      return;
    }

    const results = fuse.search(value);
    const extractedResumes = results.map((result) => result.item);
    setResumes(extractedResumes);
  };

  const handleToggle = () => {
    setEnabled(!enabled);
    updateCompanyInfo({
      ...company,
      replaceContact: !enabled,
    });
  };

  return (
    <>
      <div>
        <Helmet>
          <title>
            {t('dashboard.title')} | {t('shared.appName')}
          </title>
          <link rel="canonical" href="https://happycv.se/app/dashboard" />
        </Helmet>

        <TopNavbar />

        <h1 className="text-center text-5xl font-bold mt-4">
          {t('dashboard.title')}
        </h1>
        <h2 className="text-center text-2xl my-5">
          Number of CVs : {resumes.length}
        </h2>

        <Input
          type="text"
          className="mb-8 text-2xl w-1/3 mx-auto"
          placeholder="Filter by space separated keywords. (e.g. Python Java) "
          onChange={handleSearch}
        />

        <div className="my-15 container flex">
          <div className="mx-auto">
            <Button title="" className="my-5" onClick={handleGoToReadOnly}>
              {t('dashboard.readOnly')}
            </Button>
            <Button title="" className="mx-auto" onClick={handleGoToArchive}>
              {t('dashboard.archive')}
            </Button>
          </div>

          <Switch
            checked={enabled}
            onChange={handleToggle}
            className={classNames(
              enabled ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-7 w-14 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-6 w-7 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>

          <Tooltip
            title="Turn on to overwrite resumes contact info (Email, Phone) with company info on Read Only page. Turn off to remove logo for brokers."
            placement="top"
            arrow
          >
            <span>
              <CiCircleQuestion className="text-3xl mx-2" />
            </span>
          </Tooltip>
        </div>

        <div className="container mt-12 px-12 xl:px-0">
          <div className="grid grid-cols-1 gap-x-6 gap-y-10 md:grid-cols-4 lg:grid-cols-6 lg:gap-x-8">
            <CreateResume numOfResumes={resumes.length} maxCount={maxCount} />

            {resumes.map((x) => (
              <ResumePreview key={x.id} resume={x} />
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
