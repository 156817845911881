import { MdAdd } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext } from 'react';
import { toast } from 'react-toastify';
import { graphql, useStaticQuery } from 'gatsby';
import * as styles from './CreateResume.module.css';
import { handleKeyUp } from '../../utils';
import ModalContext from '../../contexts/ModalContext';

const createResumeButtonDataTestId = 'create-resume-button';

const CreateResume = ({ numOfResumes, maxCount }) => {
  const { t } = useTranslation();
  const { emitter, events } = useContext(ModalContext);

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          maxResumeCount
        }
      }
    }
  `);

  const handleClick = () => {
    const maxNum = maxCount ?? Number(site.siteMetadata.maxResumeCount);
    if (numOfResumes >= maxNum) {
      toast.error(
        `You are reaching the max number (${maxNum}) of CVs in Free plan,
        Please contact us if you want to upgrade your plan.`,
      );
    } else {
      emitter.emit(events.CREATE_RESUME_MODAL);
    }
  };

  return (
    <div className={styles.resume}>
      <div className={styles.backdrop}>
        <MdAdd size="48" />
      </div>
      <div
        data-testid={createResumeButtonDataTestId}
        tabIndex="0"
        role="button"
        className={styles.page}
        onClick={handleClick}
        onKeyUp={(e) => handleKeyUp(e, handleClick)}
      >
        <MdAdd size="48" />
      </div>
      <div className={styles.meta}>
        <p>{t('dashboard.createResume')}</p>
      </div>
    </div>
  );
};

export default memo(CreateResume);

export { createResumeButtonDataTestId };
